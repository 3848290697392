<template>
  <asterix-form-modal
    modal-class="sm:max-w-md"
    title="Frequency capping"
    closable
    @submit="submitForm"
    @cancel="closeModal"
  >
    <template #content>
      <div class="overflow-visible pb-8 px-3 w-full">
        <form-row class="pb-4">
          <sun-label-group class="w-full" text="Time">
            <sun-select
              v-model="frequencyCapping.type"
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              close-on-select
              :error="formErrors.type"
              label="name"
              name="type"
              :options="timeList"
              required="required"
              text-error="This field is required"
              track-by="id"
              @change="formErrors.type = false"
            />
          </sun-label-group>
        </form-row>
        <form-row>
          <sun-label-group class="w-full" text="Impressions">
            <asterix-input
              id="enter-impressions"
              v-model="frequencyCapping.value"
              :auto-validate="false"
              :disabled="isLoading"
              :min="0"
              required="required"
              type="number"
              validate-on-blur
            />
          </sun-label-group>
        </form-row>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap justify-between sm:px-3">
        <save-button
          id="frequency-capping-submit"
          :loading="isLoading"
          variant="pill"
          class="w-full text-sm custom-p-1 sm:w-auto"
        >
          <template #text> Apply </template>
        </save-button>
        <sun-button variant="pill" color="white" class="custom-p-1 text-sm hover:bg-gray-100" @click="closeModal">
          Cancel
        </sun-button>
      </div>
    </template>
  </asterix-form-modal>
</template>

<script>
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import SaveButton from '@/components/atoms/SaveButton';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import FrequencyCapping from '@/model/modules/socialAudience/targeting/FrequencyCapping';

export default {
  name: 'FrequencyCappingModal',
  components: {
    AsterixFormModal,
    SaveButton,
    FormRow,
    AsterixInput,
  },
  props: {
    targetItem: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isLoading: false,
    timeList: FrequencyCapping.TIME_TYPES,
    formErrors: {
      type: false,
    },
    frequencyCapping: new FrequencyCapping(),
  }),
  computed: {},
  created() {
    if (this.targetItem.value.length) {
      this.frequencyCapping.type = this.targetItem.value[0].type;
      this.frequencyCapping.value = this.targetItem.value[0].value;
    }
  },
  methods: {
    submitForm(form) {
      if (!form.valid) return;

      this.targetItem.value = [this.frequencyCapping];
      this.closeModal();
    },
    closeModal() {
      this.$emit('cancel');
    },
  },
};
</script>
