<template>
  <div class="w-full">
    <basic-loader class="h-10 rounded block mb-8" />

    <div v-for="i in boxes" :key="i" class="mb-6">
      <div class="flex mb-4">
        <basic-loader class="h-4 rounded w-4" />
        <basic-loader class="h-4 rounded ml-4 w-1/2" />
      </div>
      <div class="ml-8">
        <div class="flex mb-4">
          <basic-loader class="h-4 rounded w-4" />
          <basic-loader class="h-4 rounded ml-4 w-full" />
        </div>
        <div class="flex mb-4">
          <basic-loader class="h-4 rounded w-4" />
          <basic-loader class="h-4 rounded ml-4 w-1/2" />
        </div>
        <div class="flex mb-4">
          <basic-loader class="h-4 rounded w-4" />
          <basic-loader class="h-4 rounded ml-4 w-full" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BasicLoader from '@/components/atoms/Loaders/BasicLoader';

export default {
  name: 'ContentTargetingLoader',
  components: {
    BasicLoader,
  },
  props: {
    boxes: {
      type: Number,
      default: 4,
    },
  },
};
</script>
