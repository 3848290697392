<template>
  <div>
    <targeting-native
      v-if="campaign.type.id === NATIVE_TYPE.id"
      :campaign="campaign"
      :read-only="readOnly"
      v-bind="$attrs"
    ></targeting-native>
    <targeting-video
      v-else-if="campaign.type.id === VIDEO_TYPE.id"
      :campaign="campaign"
      :read-only="readOnly"
      v-bind="$attrs"
    ></targeting-video>
  </div>
</template>

<script>
import TargetingNative from '@/components/organisms/modules/socialAudience/campaign/form/targeting/TargetingNative';
import TargetingVideo from '@/components/organisms/modules/socialAudience/campaign/form/targeting/TargetingVideo';
import { NATIVE_TYPE, VIDEO_TYPE } from '@/model/modules/socialAudience/campaign/CampaignTypes';
import Campaign from '@/entities/socialAudience/Campaign';

export default {
  name: 'TargetingHandler',
  components: {
    TargetingNative,
    TargetingVideo,
  },
  props: {
    campaign: {
      type: Campaign,
      default: () => null,
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    NATIVE_TYPE: () => NATIVE_TYPE,
    VIDEO_TYPE: () => VIDEO_TYPE,
  },
};
</script>
