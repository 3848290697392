var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-modal", {
    attrs: {
      "modal-class": "sm:max-w-md modal-content h-full",
      title: "Content targeting",
      closable: "",
    },
    on: { cancel: _vm.closeModal },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _vm.isLoading
              ? _c("content-targeting-loader")
              : _c(
                  "div",
                  { staticClass: "mb-6 w-full" },
                  [
                    _c("sun-search-input", {
                      staticClass: "mb-4 w-full",
                      attrs: { value: _vm.searchTerm },
                      on: { search: _vm.filter },
                    }),
                    _vm.root
                      ? _c("multi-checkbox-tree", {
                          staticClass: "text-xs",
                          attrs: {
                            value: _vm.root,
                            selected: _vm.selected,
                            "checkbox-all": "",
                          },
                          on: { change: _vm.setSelectedItems },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "sun-button",
              {
                staticClass: "custom-p-1 text-sm mr-2",
                attrs: { color: "orange", variant: "pill" },
                on: { click: _vm.addItems },
              },
              [_vm._v(" Apply ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }