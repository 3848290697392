<template>
  <div v-if="value.visible" class="flex flex-col">
    <checkbox-simple
      :label="label"
      :can-open="hasChildren"
      color="orange"
      :selected="allSelected"
      text-color="white"
      @change="addAll"
      @open="showChildren = true"
      @close="showChildren = false"
    />
    <transition name="fade">
      <div v-show="showChildren">
        <div v-if="hasChildren" :key="keyGroupCheckbox">
          <div v-for="(item, index) in value.children" :key="index" class="pl-6">
            <multi-checkbox-tree
              :key="'mc-' + index"
              class="mt-1 text-xs"
              :selected="getSelectedItems(item.id)"
              :value="item"
              @change="changeItems"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import CheckboxSimple from './CheckboxSimple';

export default {
  name: 'MultiCheckboxTree',
  components: {
    CheckboxSimple,
    MultiCheckboxTree: () => import('./MultiCheckboxTree'),
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      keyGroupCheckbox: 0,
      selectedItems: [],
      mapChildren: new Map(),
      showChildren: true,
    };
  },
  computed: {
    allSelected() {
      if (this.hasChildren) {
        if (this.selectedItems.length === 0) return false;
        if (this.selectedItems.length !== this.value.children.length) return false;

        return this.selectedItems.every(
          item => this.mapChildren.has(item.id) && this.mapChildren.get(item.id).length > 0
        );
      }
      return this.isSelected;
    },
    isSelected() {
      return this.selectedItems.findIndex(item => item.id === this.value.id) >= 0;
    },
    hasChildren() {
      return this.value?.children.length > 0;
    },
    label() {
      return this.value.name;
    },
  },
  watch: {
    selected: {
      deep: true,
      handler(newItems) {
        if (!this.hasChildren) {
          this.selectedItems = [...newItems];
          return;
        }
        this.setNewItems(newItems);
      },
    },
  },
  mounted() {
    this.initMapChildren();

    if (!this.hasChildren) {
      this.selectedItems = [...this.selected];
      return;
    }
    this.setNewItems([...this.selected]);
  },
  methods: {
    initMapChildren() {
      this.value.children.forEach(child => {
        this.mapChildren.set(child.id, []);
      });
    },
    changeItems({ key, items }) {
      this.mapChildren.set(key, items);
      this.selectedItems = this.getChildrenSelected();

      this.$emit('change', { key: this.value.id, items: this.allSelected ? [this.value] : this.selectedItems });
    },
    addAll({ checked }) {
      if (checked) {
        this.selectedItems = this.value.children.length > 0 ? Array.from(this.value.children) : [this.value];
      } else {
        this.selectedItems = [];
      }

      this.keyGroupCheckbox++;
      this.$emit('change', {
        key: this.value.id,
        items: checked ? [this.value] : this.selectedItems,
      });
    },
    getSelectedItems(keyChild) {
      return this.selectedItems.filter(item => item.parentId.includes(keyChild) || item.id === keyChild);
    },
    getChildrenSelected() {
      const arrayValues = Array.from(this.mapChildren.values());
      let joinItems = [];
      arrayValues.forEach(array => {
        joinItems = joinItems.concat(array);
      });

      return joinItems;
    },
    setNewItems(newItems) {
      if (newItems.findIndex(item => item.id === this.value.id) >= 0) {
        this.value.children.forEach(child => this.mapChildren.set(child.id, [child]));
      } else {
        this.value.children.forEach(child => {
          const array = newItems.filter(newItem => newItem.id === child.id || newItem.parentId.includes(child.id));
          this.mapChildren.set(child.id, array);
        });
      }

      this.selectedItems = this.getChildrenSelected();
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
</style>
