<template>
  <div class="flex flex-col justify-start items-start w-full p-4 border-gray-200">
    <div class="flex w-full justify-start items-start justify-between">
      <span class="text-base text-gray-700 font-bold">
        {{ include.name }}
      </span>
      <div class="flex w-full items-center flex-1 justify-end">
        <sun-button
          class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800 relative"
          color="gray"
          :disabled="readOnly"
          variant="pill"
          @click="emitEdit(include)"
        >
          Edit
        </sun-button>
      </div>
    </div>
    <slot name="targetList">
      <item-list v-if="anyInclude" can-remove color="green" :list="includes" v-on="$listeners">
        <template v-if="showSectionIncludeTitle" #title>Includes</template>
        <template #item-text="{ item }">
          <slot name="item-text" :item="item">
            {{ item.name | name(filterName) }}
          </slot>
        </template>
      </item-list>
      <item-list v-if="anyExclude" :list="excludes" color="red">
        <template v-if="showSectionIncludeTitle" #title>Excludes</template>
        <template #item-text="{ item }">
          <slot name="item-text" :item="item">
            {{ item.name | name(filterName) }}
          </slot>
        </template>
      </item-list>
    </slot>
  </div>
</template>
<script>
import IncludeItemNew from '@/model/shared/IncludeItemNew';
import ItemList from './ItemList';

export default {
  name: 'IncludeItemListMultiple',
  components: {
    ItemList,
  },
  filters: {
    name(value, filter) {
      return filter(value);
    },
  },
  props: {
    /** @Type<IncludeItemNew> */
    include: {
      type: Object,
      required: true,
    },
    filterName: {
      type: Function,
      default: value => value,
    },
    allowExclude: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    includes() {
      return this.include.value
        .filter(item => item.type === IncludeItemNew.VALID_TYPES.INCLUDE)
        .map(item => item.value);
    },
    anyInclude() {
      return !!this.includes.length;
    },
    excludes() {
      return this.include.value
        .filter(item => item.type === IncludeItemNew.VALID_TYPES.EXCLUDE)
        .map(item => item.value);
    },
    anyExclude() {
      return !!this.excludes.length;
    },
    showSectionIncludeTitle() {
      return this.include.canExclude;
    },
  },
  methods: {
    emitEdit(key) {
      this.$emit('edit', key);
    },
  },
};
</script>
