<template>
  <div v-if="!!includes.length">
    <header class="flex justify-between items-center">
      <h1 class="text-xl text-gray-700 font-bold"></h1>
      <asterix-dropdown-multiple v-model="includes" class="mb-4" :disabled="readOnly" @change="onNewInclude">
        + add
      </asterix-dropdown-multiple>
    </header>
    <ul v-if="includesToShow.length">
      <li v-for="includeShowned in includesToShow" :key="includeShowned.id" class="item-list">
        <include-item-list-multiple
          allow-exclude
          :include="includeShowned"
          :read-only="readOnly"
          @edit="onNewInclude"
          @remove="removeItem(includeShowned.id, $event)"
        >
          <template v-if="includeShowned.id === TARGET_IDS.FREQUENCY_CAPPING" #targetList>
            <item-list
              can-remove
              color="blue"
              :list="includeShowned.value"
              @remove="removeItem(includeShowned.id, $event)"
            >
              <template #item-text="{ item }"> {{ item.value | numFormat }} per {{ item.type.name }} </template>
            </item-list>
          </template>
          <template v-else-if="includeShowned.id === TARGET_IDS.CONTENT_TARGETING" #targetList>
            <item-list can-remove :list="includeShowned.value" @remove="removeItem(includeShowned.id, $event)">
              <template #item-text="{ item }">
                {{ item.name }}
              </template>
            </item-list>
          </template>
          <template v-else #item-text="{ item }">
            <slot name="item-text" :item="item" :include="includeShowned"></slot>
          </template>
        </include-item-list-multiple>
      </li>
    </ul>
    <div v-else>
      <asterix-no-data
        title="No includes yet"
        subtitle="Use the add button above to create your first including"
        class="text-center"
      />
    </div>
    <frequency-capping-modal
      v-if="includeToCreate && includeToCreate.id === TARGET_IDS.FREQUENCY_CAPPING"
      :target-item="includeToCreate"
      @cancel="onNewIncludeCancel"
    />
    <content-targeting-modal
      v-else-if="includeToCreate && includeToCreate.id === TARGET_IDS.CONTENT_TARGETING"
      :target-item="includeToCreate"
      @cancel="onNewIncludeCancel"
    />
    <include-modal-multiple
      v-else-if="includeToCreate"
      :base-include="includeToCreate"
      :allow-exclude="allowExclude"
      :title="includeToCreate.name"
      @cancel="onNewIncludeCancel"
      @update="onNewIncludeAdded"
    />
  </div>
</template>

<script>
import AsterixNoData from '@/components/organisms/shared/AsterixNoData';
import AsterixDropdownMultiple from '@/components/molecules/shared/AsterixDropdownMultiple';
import IncludeItemListMultiple from '@/components/organisms/shared/includes/IncludeItemListMultiple';
import IncludeModalMultiple from '@/components/organisms/shared/includes/IncludeModalMultiple';
import FrequencyCappingModal from '@/components/organisms/modules/socialAudience/campaign/form/targeting/FrequencyCappingModal';
import ContentTargetingModal from '@/components/organisms/modules/socialAudience/campaign/form/targeting/ContentTargetingModal';
import ItemList from './ItemList';
import TargetVideo from '@/entities/socialAudience/TargetVideo';

export default {
  components: {
    AsterixNoData,
    AsterixDropdownMultiple,
    IncludeItemListMultiple,
    IncludeModalMultiple,
    FrequencyCappingModal,
    ContentTargetingModal,
    ItemList,
  },
  props: {
    includes: {
      type: Array,
      required: true,
    },
    allowExclude: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    includeToCreate: null,
  }),
  computed: {
    includesToShow() {
      return this.includes.filter(include => include.value.length);
    },
    TARGET_IDS: () => TargetVideo.TARGET_IDS,
  },
  created() {
    this.includes.forEach(include => {
      this.$set(include, 'selected', !!include.value.length);
    });
  },
  methods: {
    onNewInclude(include) {
      this.includeToCreate = include;
    },
    onNewIncludeCancel() {
      this.$set(this.includeToCreate, 'selected', !!this.includeToCreate.value.length);
      this.includeToCreate = null;
    },
    onNewIncludeAdded(include) {
      this.includeToCreate = null;

      const update = this.includes.find(t => t.id === include.id);
      this.$set(update, 'value', include.value);
      this.$set(update, 'selected', !!include.value.length);

      this.$emit('input', update);
    },
    removeItem(includeId, itemId) {
      const include = this.includes.find(include => include.id === includeId);
      include.value = include.value.filter(item => item?.id !== itemId && item?.value?.id !== itemId);
      this.$set(include, 'selected', !!include.value.length);
    },
  },
};
</script>

<style scoped>
.item-list {
  @apply border-t border-r border-l;
}
.item-list:first-child {
  @apply rounded-t-lg;
}
.item-list:last-child {
  @apply border-b rounded-b-lg;
}
</style>
