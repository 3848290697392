<template>
  <div v-if="!!includes.length">
    <header class="flex justify-between items-center">
      <h1 class="text-xl text-gray-700 font-bold"></h1>
      <asterix-dropdown-multiple v-model="includes" class="mb-4" :disabled="readOnly" @change="onNewInclude">
        + add
      </asterix-dropdown-multiple>
    </header>
    <ul v-if="includesToShow.length">
      <li v-for="includeShowned in includesToShow" :key="includeShowned.id" class="item-list">
        <include-item-list
          :allow-exclude="allowExclude"
          :include="includeShowned"
          :read-only="readOnly"
          @edit="onNewInclude"
          @remove="removeItem(includeShowned.id, $event)"
        >
          <template #item-text="{ item }">
            <slot name="item-text" :item="item" :include="includeShowned">
              {{ item.name }}
            </slot>
          </template>
        </include-item-list>
      </li>
    </ul>
    <div v-else>
      <asterix-no-data
        title="No includes yet"
        subtitle="Use the add button above to create your first including"
        class="text-center"
      />
    </div>
    <include-modal
      v-if="includeToCreate"
      :base-include="includeToCreate"
      :allow-exclude="allowExclude"
      :title="includeToCreate.name"
      @cancel="onNewIncludeCancel"
      @update="onNewIncludeAdded"
    />
  </div>
</template>

<script>
import AsterixNoData from '@/components/organisms/shared/AsterixNoData';
import AsterixDropdownMultiple from '@/components/molecules/shared/AsterixDropdownMultiple';
import IncludeItem from '@/model/shared/IncludeItem';
import IncludeItemList from '@/components/organisms/shared/includes/IncludeItemList';
import IncludeModal from '@/components/organisms/shared/includes/IncludeModal';

export default {
  components: {
    AsterixNoData,
    AsterixDropdownMultiple,
    IncludeItemList,
    IncludeModal,
  },
  props: {
    includes: {
      type: Array,
      required: true,
    },
    allowExclude: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    includeToCreate: null,
  }),
  computed: {
    includesToShow() {
      return this.includes.filter(
        include => include.value.type !== IncludeItem.VALID_TYPES.NONE && include.value.length
      );
    },
  },
  created() {
    this.includes.forEach(include => {
      this.$set(include, 'selected', !!include.value.length);
    });
  },
  methods: {
    onNewInclude(include) {
      this.includeToCreate = include;
    },
    onNewIncludeCancel() {
      this.$set(this.includeToCreate, 'selected', !!this.includeToCreate.value.length);
      this.includeToCreate = null;
    },
    onNewIncludeAdded(include) {
      this.includeToCreate = null;

      const update = this.includes.find(t => t.id === include.id);
      this.$set(update, 'value', include.value);
      this.$set(update, 'type', include.type);
      this.$set(update, 'selected', !!include.value.length);

      this.$emit('input', update);
    },
    removeItem(includeId, itemId) {
      const include = this.includes.find(include => include.id === includeId);
      include.value = include.value.filter(item => item.id !== itemId);
      const isIncludeSelected = !!include.value.length;
      this.$set(include, 'selected', isIncludeSelected);
      this.$set(include, 'type', isIncludeSelected ? include.type : IncludeItem.VALID_TYPES.NONE);
    },
  },
};
</script>

<style scoped>
.item-list {
  @apply border-t border-r border-l;
}
.item-list:first-child {
  @apply rounded-t-lg;
}
.item-list:last-child {
  @apply border-b rounded-b-lg;
}
</style>
