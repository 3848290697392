var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "asterix-modal",
    {
      attrs: {
        "modal-class": "asterix-modal-size h-full",
        title: _vm.title,
        closable: "",
      },
      on: {
        cancel: function ($event) {
          return _vm.$emit("cancel")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "sun-button",
                {
                  staticClass: "custom-p-1 text-sm mr-2",
                  attrs: { color: "orange", variant: "pill" },
                  on: { click: _vm.addInclude },
                },
                [_vm._v(" Apply ")]
              ),
              _c(
                "sun-button",
                {
                  staticClass: "custom-p-1 text-sm hover:bg-gray-100",
                  attrs: { variant: "pill", color: "white" },
                  on: { click: _vm.cancelInclude },
                },
                [_vm._v(" Cancel ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "flex w-full h-80vh overflow-hidden" }, [
          _c(
            "div",
            { staticClass: "pt-1 w-1/2 relative flex flex-col w-1/2" },
            [
              _c("target-header-modal", {
                attrs: {
                  "can-exclude": _vm.isIncludeMultiple && _vm.includeCanExclude,
                  "can-include": _vm.isIncludeMultiple,
                  "is-exclude-selected": _vm.isExcludeAllSelected,
                  "is-include-selected": _vm.isIncludeAllSelected,
                  loading: _vm.isLoading,
                },
                on: {
                  exclude: _vm.excludeAllItems,
                  include: _vm.includeAllItems,
                  search: _vm.search,
                },
              }),
              _vm.isLoading
                ? _c("include-option-list-loader", {
                    staticClass: "px-2 overflow-hidden",
                  })
                : _c("target-options-list", {
                    ref: "fixedContainer",
                    attrs: {
                      "can-exclude": "",
                      "can-include": "",
                      excludes: _vm.excludes,
                      includes: _vm.includes,
                      "is-exclude-visible": _vm.includeCanExclude,
                      multiple: _vm.isIncludeMultiple,
                      options: _vm.optionsFiltered,
                    },
                    on: {
                      exclude: _vm.onExcludePick,
                      include: _vm.onIncludePick,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "option",
                        fn: function ({ option }) {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.getOptionName(option)) + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
            ],
            1
          ),
          !_vm.anyInclude && !_vm.anyExclude
            ? _c(
                "div",
                { staticClass: "w-1/2" },
                [
                  _c("asterix-no-data", {
                    staticClass: "text-center p-2",
                    attrs: {
                      title: "There aren't items added",
                      subtitle:
                        "You can add them using the controls on the option list",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "w-1/2" },
                [
                  _vm.anyInclude
                    ? _c("target-options-selected-list", {
                        staticClass: "options-selected",
                        class: { "h-50": _vm.anyExclude },
                        attrs: { items: _vm.includes },
                        on: { remove: _vm.deleteFromList },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" Includes ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getOptionNameById(item)) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3766937265
                        ),
                      })
                    : _vm._e(),
                  _vm.anyExclude
                    ? _c("target-options-selected-list", {
                        staticClass: "options-selected",
                        class: { "h-50": _vm.anyInclude },
                        attrs: { items: _vm.excludes },
                        on: { remove: _vm.deleteFromList },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" Excludes ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getOptionNameById(item)) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2484035947
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }