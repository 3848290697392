var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-form-modal", {
    attrs: {
      "modal-class": "sm:max-w-md",
      title: "Frequency capping",
      closable: "",
    },
    on: { submit: _vm.submitForm, cancel: _vm.closeModal },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "overflow-visible pb-8 px-3 w-full" },
              [
                _c(
                  "form-row",
                  { staticClass: "pb-4" },
                  [
                    _c(
                      "sun-label-group",
                      { staticClass: "w-full", attrs: { text: "Time" } },
                      [
                        _c("sun-select", {
                          attrs: {
                            "add-hex-color": "orange",
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            "close-on-select": "",
                            error: _vm.formErrors.type,
                            label: "name",
                            name: "type",
                            options: _vm.timeList,
                            required: "required",
                            "text-error": "This field is required",
                            "track-by": "id",
                          },
                          on: {
                            change: function ($event) {
                              _vm.formErrors.type = false
                            },
                          },
                          model: {
                            value: _vm.frequencyCapping.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.frequencyCapping, "type", $$v)
                            },
                            expression: "frequencyCapping.type",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "form-row",
                  [
                    _c(
                      "sun-label-group",
                      { staticClass: "w-full", attrs: { text: "Impressions" } },
                      [
                        _c("asterix-input", {
                          attrs: {
                            id: "enter-impressions",
                            "auto-validate": false,
                            disabled: _vm.isLoading,
                            min: 0,
                            required: "required",
                            type: "number",
                            "validate-on-blur": "",
                          },
                          model: {
                            value: _vm.frequencyCapping.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.frequencyCapping, "value", $$v)
                            },
                            expression: "frequencyCapping.value",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between sm:px-3" },
              [
                _c("save-button", {
                  staticClass: "w-full text-sm custom-p-1 sm:w-auto",
                  attrs: {
                    id: "frequency-capping-submit",
                    loading: _vm.isLoading,
                    variant: "pill",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "text",
                      fn: function () {
                        return [_vm._v(" Apply ")]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c(
                  "sun-button",
                  {
                    staticClass: "custom-p-1 text-sm hover:bg-gray-100",
                    attrs: { variant: "pill", color: "white" },
                    on: { click: _vm.closeModal },
                  },
                  [_vm._v(" Cancel ")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }