import { api } from '.';
import { INSERTION_RESOURCE, TARGETING_RESOURCE } from '@/services/keys';

export const getResources = insertionId => ({
  [INSERTION_RESOURCE]: insertionId,
  [TARGETING_RESOURCE]: undefined,
});

/**
 *
 * @param {Target} targeting
 * @param {String} insertionId
 * @returns {Promise.<string>}
 */
export async function createTargeting(targeting, insertionId) {
  const partialUrl = api.createUrl(getResources(insertionId));
  const {
    data: { id },
  } = await api.create(partialUrl, targeting.payload());
  return id;
}
