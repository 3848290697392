var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "w-full text-sm" },
        [
          _vm.target
            ? _c(
                "sun-form",
                { key: _vm.keyForm, on: { submit: _vm.onSubmit } },
                [
                  _c("card-form", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v("Include list")]
                          },
                          proxy: true,
                        },
                        {
                          key: "form",
                          fn: function () {
                            return [
                              _c("include-creator-multiple", {
                                attrs: { includes: _vm.includes },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item-text",
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(" " + _vm._s(item.name) + " "),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2107715094
                                ),
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2279770041
                    ),
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-4 mt-12 form-row flex flex-col lg:flex-row w-full",
                    },
                    [
                      _c("save-button", {
                        staticClass: "mx-1",
                        attrs: {
                          disabled: _vm.readOnly,
                          loading: _vm.isSubmiting,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        [
          _c("card-form-loading"),
          _c("card-form-loading", { staticClass: "mt-10" }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }