<template>
  <asterix-dropdown :options="targetOptions" :disabled="disabled" @change="onOptionsChange">
    <template #button>
      <sun-button
        variant="pill"
        class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800 relative"
        color="gray"
        :disabled="disabled"
      >
        <slot>Select</slot>
      </sun-button>
    </template>

    <template #option="slotProps">
      <div
        class="target-list-item flex justify-between px-3 text-xs font-bold text-gray-500 hover:text-white hover:bg-gray-700 rounded-full"
      >
        <p>
          {{ slotProps.option.option.name }}
        </p>
        <p
          v-if="addedOptions.findIndex(t => t.id === slotProps.option.option.id) > -1"
          class="ml-6 text-xxs text-gray-600"
        >
          <slot name="added"> Added </slot>
        </p>
      </div>
    </template>
  </asterix-dropdown>
</template>

<script>
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown';

export default {
  name: 'AsterixDropdownMultiple',
  components: { AsterixDropdown },
  props: {
    value: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    targetOptions: [],
  }),
  computed: {
    addedOptions() {
      return this.targetOptions.filter(opt => opt?.selected);
    },
  },
  watch: {
    value(newVal) {
      this.targetOptions = newVal;
    },
  },
  created() {
    this.targetOptions = this.value;
  },
  methods: {
    onOptionsChange(target) {
      const opt = this.targetOptions.find(opt => opt.id === target.id);
      if (opt) {
        this.$set(opt, 'selected', true);
      }
      this.$emit('input', this.targetOptions);
      this.$emit('change', target);
    },
  },
};
</script>

<style scoped>
.target-list-item {
  min-width: 10rem;
}
</style>
