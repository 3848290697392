var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "w-full text-sm" },
        [
          _vm.target
            ? _c(
                "sun-form",
                { key: _vm.keyForm, on: { submit: _vm.onSubmit } },
                [
                  _c("card-form", {
                    staticClass: "block-list",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v("Domain List")]
                          },
                          proxy: true,
                        },
                        {
                          key: "form",
                          fn: function () {
                            return [
                              _c(
                                "sun-label-group",
                                [
                                  _c("sun-select", {
                                    attrs: {
                                      disabled: _vm.readOnly,
                                      placeholder: "Add domains",
                                      label: "domain-list",
                                      "color-tag": "gray",
                                      options: [],
                                      multiple: "",
                                      taggable: "",
                                    },
                                    on: {
                                      tag: function ($event) {
                                        return _vm.target.domains.push($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.target.domains,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.target, "domains", $$v)
                                      },
                                      expression: "target.domains",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1769918773
                    ),
                  }),
                  _c("card-form", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v("Include list")]
                          },
                          proxy: true,
                        },
                        {
                          key: "form",
                          fn: function () {
                            return [
                              _c("include-creator", {
                                attrs: { includes: _vm.includes },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item-text",
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(" " + _vm._s(item.name) + " "),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2107715094
                                ),
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3852227972
                    ),
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-4 mt-12 form-row flex flex-col lg:flex-row w-full",
                    },
                    [
                      _c("save-button", {
                        staticClass: "mx-1",
                        attrs: {
                          disabled: _vm.readOnly,
                          loading: _vm.isSubmiting,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        [
          _c("card-form-loading"),
          _c("card-form-loading", { staticClass: "mt-10" }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }