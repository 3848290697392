var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col justify-start items-start w-full p-4 border-gray-200",
    },
    [
      _c(
        "div",
        {
          staticClass: "flex w-full justify-start items-start justify-between",
        },
        [
          _c("span", { staticClass: "text-base text-gray-700 font-bold" }, [
            _vm._v(" " + _vm._s(_vm.include.name) + " "),
          ]),
          _c(
            "div",
            { staticClass: "flex w-full items-center flex-1 justify-end" },
            [
              _c(
                "sun-button",
                {
                  staticClass:
                    "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800 relative",
                  attrs: {
                    color: "gray",
                    disabled: _vm.readOnly,
                    variant: "pill",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.emitEdit(_vm.include)
                    },
                  },
                },
                [_vm._v(" Edit ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._t("targetList", function () {
        return [
          _vm.anyInclude
            ? _c(
                "item-list",
                _vm._g(
                  {
                    attrs: {
                      "can-remove": "",
                      color: "green",
                      list: _vm.includes,
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.showSectionIncludeTitle
                          ? {
                              key: "title",
                              fn: function () {
                                return [_vm._v("Includes")]
                              },
                              proxy: true,
                            }
                          : null,
                        {
                          key: "item-text",
                          fn: function ({ item }) {
                            return [
                              _vm._t(
                                "item-text",
                                function () {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("name")(
                                            item.name,
                                            _vm.filterName
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                                { item: item }
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  _vm.$listeners
                )
              )
            : _vm._e(),
          _vm.anyExclude
            ? _c("item-list", {
                attrs: { list: _vm.excludes, color: "red" },
                scopedSlots: _vm._u(
                  [
                    _vm.showSectionIncludeTitle
                      ? {
                          key: "title",
                          fn: function () {
                            return [_vm._v("Excludes")]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "item-text",
                      fn: function ({ item }) {
                        return [
                          _vm._t(
                            "item-text",
                            function () {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("name")(item.name, _vm.filterName)
                                    ) +
                                    " "
                                ),
                              ]
                            },
                            { item: item }
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }