var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value.visible
    ? _c(
        "div",
        { staticClass: "flex flex-col" },
        [
          _c("checkbox-simple", {
            attrs: {
              label: _vm.label,
              "can-open": _vm.hasChildren,
              color: "orange",
              selected: _vm.allSelected,
              "text-color": "white",
            },
            on: {
              change: _vm.addAll,
              open: function ($event) {
                _vm.showChildren = true
              },
              close: function ($event) {
                _vm.showChildren = false
              },
            },
          }),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showChildren,
                    expression: "showChildren",
                  },
                ],
              },
              [
                _vm.hasChildren
                  ? _c(
                      "div",
                      { key: _vm.keyGroupCheckbox },
                      _vm._l(_vm.value.children, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "pl-6" },
                          [
                            _c("multi-checkbox-tree", {
                              key: "mc-" + index,
                              staticClass: "mt-1 text-xs",
                              attrs: {
                                selected: _vm.getSelectedItems(item.id),
                                value: item,
                              },
                              on: { change: _vm.changeItems },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }