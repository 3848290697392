<template>
  <asterix-modal modal-class="sm:max-w-md modal-content h-full" title="Content targeting" closable @cancel="closeModal">
    <template #content>
      <content-targeting-loader v-if="isLoading" />
      <div v-else class="mb-6 w-full">
        <sun-search-input :value="searchTerm" class="mb-4 w-full" @search="filter" />
        <multi-checkbox-tree
          v-if="root"
          class="text-xs"
          :value="root"
          :selected="selected"
          checkbox-all
          @change="setSelectedItems"
        />
      </div>
    </template>

    <template #footer>
      <sun-button color="orange" variant="pill" class="custom-p-1 text-sm mr-2" @click="addItems"> Apply </sun-button>
    </template>
  </asterix-modal>
</template>
<script>
import AsterixModal from '@/components/organisms/shared/AsterixModal';
import MultiCheckboxTree from '@/components/organisms/modules/socialAudience/campaign/form/targeting/MultiCheckboxTree/MultiCheckboxTree';
import { VIDEO_PLAZA } from '@/model/shared/Platforms';
import ContentTargetingLoader from './ContentTargetingLoader';

export default {
  name: 'ContentTargetingModal',
  components: {
    AsterixModal,
    MultiCheckboxTree,
    ContentTargetingLoader,
  },
  inject: ['services'],
  props: {
    /** @Type<TargetItem> */
    targetItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      contentTargetingList: [],
      root: null,
      selected: [],
      searchTerm: null,
    };
  },
  async beforeMount() {
    await this.setRoot();
    this.selected = this.targetItem.value;
  },
  methods: {
    async setRoot() {
      this.isLoading = true;
      const { data } = await this.services[this.targetItem.id]();
      this.contentTargetingList = data;
      this.root = this.contentTargetingToTree(data);
      this.isLoading = false;
    },
    contentTargetingToTree(array) {
      if (!array.length) return null;

      const levels = new Map();
      array.forEach(item => {
        const level = item.parentId.length.toString();
        if (!levels.has(level)) levels.set(level, []);

        const levelItems = levels.get(level);
        levelItems.push(item);
        levels.set(level, levelItems);
      });

      for (let i = 1; i < levels.size; i++) {
        const previousLevel = levels.get((i - 1).toString());
        const currentLevel = levels.get(i.toString());

        for (const currentItem of previousLevel) {
          currentItem.children = currentLevel.filter(item =>
            item.parentId.includes(currentItem.platform[VIDEO_PLAZA.id])
          );
        }
      }

      levels.get((levels.size - 1).toString()).forEach(item => (item.children = []));
      return Object.create(levels.get('0')[0]);
    },
    setSelectedItems({ items }) {
      this.selected = items;
    },
    addItems() {
      this.targetItem.value = this.selected;
      this.closeModal();
    },
    closeModal() {
      this.$emit('cancel');
    },
    filter(term) {
      this.searchTerm = term;

      const contentTargetingListFiltered = this.contentTargetingList.filter(item =>
        item.name.toLowerCase().includes(term.toLowerCase())
      );

      const ids = new Set();
      contentTargetingListFiltered.forEach(item => {
        ids.add(item.id);
        item.parentId.forEach(id => ids.add(id));
      });

      const contentTargetingListVisible = this.contentTargetingList.map(item => ({
        ...item,
        visible: ids.has(item.id),
      }));
      this.root = this.contentTargetingToTree(contentTargetingListVisible);
    },
  },
};
</script>
<style scoped>
.modal-content {
  max-height: 83vh;
}
</style>
