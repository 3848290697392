var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.campaign.type.id === _vm.NATIVE_TYPE.id
        ? _c(
            "targeting-native",
            _vm._b(
              { attrs: { campaign: _vm.campaign, "read-only": _vm.readOnly } },
              "targeting-native",
              _vm.$attrs,
              false
            )
          )
        : _vm.campaign.type.id === _vm.VIDEO_TYPE.id
        ? _c(
            "targeting-video",
            _vm._b(
              { attrs: { campaign: _vm.campaign, "read-only": _vm.readOnly } },
              "targeting-video",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }