<template>
  <div v-if="!isLoading" class="w-full text-sm">
    <sun-form v-if="target" :key="keyForm" @submit="onSubmit">
      <card-form class="block-list">
        <template #title>Domain List</template>
        <template #form>
          <sun-label-group>
            <sun-select
              v-model="target.domains"
              :disabled="readOnly"
              placeholder="Add domains"
              label="domain-list"
              color-tag="gray"
              :options="[]"
              multiple
              taggable
              @tag="target.domains.push($event)"
            />
          </sun-label-group>
        </template>
      </card-form>
      <card-form>
        <template #title>Include list</template>
        <template #form>
          <include-creator :includes="includes">
            <template #item-text="{ item }">
              {{ item.name }}
            </template>
          </include-creator>
        </template>
      </card-form>
      <div class="mb-4 mt-12 form-row flex flex-col lg:flex-row w-full">
        <save-button class="mx-1" :disabled="readOnly" :loading="isSubmiting"></save-button>
      </div>
    </sun-form>
  </div>
  <div v-else>
    <card-form-loading></card-form-loading>
    <card-form-loading class="mt-10"></card-form-loading>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import metaInfo from '@/mixins/common/metaInfo';
import CardForm from '@/components/atoms/CardForm';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import SaveButton from '@/components/atoms/SaveButton';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import Campaign from '@/entities/socialAudience/Campaign';
import TargetNative from '@/entities/socialAudience/TargetNative';
import { createTargeting, updateTargeting, getTargetingNative } from '@/services/modules/socialAudience/targeting';
import { Toast } from '@/model/shared/Toast';
import { COUNTRY_NS, COUNTRIES_KEY, GET_COUNTRIES_REQUEST } from '@/store/modules/country/keys';
import { BROWSER_NS, BROWSERS_SMART_KEY, GET_BROWSERS_SMART_REQUEST } from '@/store/modules/browser/keys';
import { DEVICE_NS, DEVICES_SMART_KEY, GET_DEVICES_SMART_REQUEST } from '@/store/modules/device/keys';
import IncludeCreator from '@/components/organisms/shared/includes/IncludeCreator';
import { list } from '@/router/private/modules/socialAudience/demand/campaign/list';
import IncludeItem from '@/model/shared/IncludeItem';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';

export default {
  name: 'TargetingNative',
  components: {
    CardForm,
    CardFormLoading,
    SaveButton,
    IncludeCreator,
  },
  mixins: [metaInfo],
  provide() {
    return {
      services: {
        countryFilter: this.getCountries,
        browserFilter: this.getBrowsers,
        deviceFilter: this.getDevices,
      },
    };
  },
  props: {
    campaign: {
      type: Campaign,
      default: () => null,
    },
    insertionId: {
      type: String,
      default: () => null,
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    keyForm: 0,
    target: null,
    bundleBlackList: [],
    isLoading: false,
    isSubmiting: false,
  }),
  computed: {
    ...mapState(COUNTRY_NS, {
      countryList: COUNTRIES_KEY,
    }),
    ...mapState(BROWSER_NS, {
      browserList: BROWSERS_SMART_KEY,
    }),
    ...mapState(DEVICE_NS, {
      deviceList: DEVICES_SMART_KEY,
    }),
    includes() {
      return this.target
        ? Object.entries(this.target)
            ?.map(entry => entry[1])
            .filter(item => IncludeItem.isObject(item))
        : [];
    },
  },
  async created() {
    if (!this.campaign) return;

    this.isLoading = true;
    this.target = (await this.getTargeting()) || new TargetNative();
    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      getCountryList: GET_COUNTRIES_REQUEST,
      getBrowserList: GET_BROWSERS_SMART_REQUEST,
      getDeviceList: GET_DEVICES_SMART_REQUEST,
      createToast: CREATE_TOAST,
    }),
    metaTitleReplacement() {
      return { campaign: this.campaign.name };
    },
    async getTargeting() {
      try {
        const { data } = await getTargetingNative(this.insertionId);
        return data[0];
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel && error.code !== HTTPStatusCode.Locked) {
          this.createToast(Toast.error('Targeting not loaded', error.message));
        }
      }
    },
    async onSubmit() {
      this.isSubmiting = true;
      if (this.target.id) {
        await this.updateTargeting();
      } else {
        await this.createTargeting();
      }
      this.isSubmiting = false;
      this.goToCampaignList();
    },
    async createTargeting() {
      try {
        await createTargeting(this.target, this.insertionId);
        this.createToast(Toast.success('Targeting saved', `Targeting was saved successfully.`));
      } catch (error) {
        this.createToast(Toast.error('Targeting not saved', error.message));
      }
    },
    async updateTargeting() {
      try {
        await updateTargeting(this.target);
        this.createToast(Toast.success('Targeting saved', `Targeting was saved successfully.`));
      } catch (error) {
        this.createToast(Toast.error('Targeting not saved', error.message));
      }
    },
    async getCountries() {
      await this.getCountryList();

      return {
        data: this.countryList,
      };
    },
    async getBrowsers() {
      if (this.browserList.length === 0) {
        await this.getBrowserList();
      }
      return {
        data: this.browserList,
      };
    },
    async getDevices() {
      if (this.deviceList.length === 0) {
        await this.getDeviceList();
      }
      return {
        data: this.deviceList,
      };
    },
    goToCampaignList() {
      this.$router.push(list);
    },
  },
};
</script>

<style scoped>
::v-deep .block-list .multiselect__option {
  @apply hidden;
}
::v-deep .block-list .multiselect__select {
  @apply hidden;
}
::v-deep .block-list .multiselect__tags input {
  @apply mb-0;
}
</style>
