var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !!_vm.includes.length
    ? _c(
        "div",
        [
          _c(
            "header",
            { staticClass: "flex justify-between items-center" },
            [
              _c("h1", { staticClass: "text-xl text-gray-700 font-bold" }),
              _c(
                "asterix-dropdown-multiple",
                {
                  staticClass: "mb-4",
                  attrs: { disabled: _vm.readOnly },
                  on: { change: _vm.onNewInclude },
                  model: {
                    value: _vm.includes,
                    callback: function ($$v) {
                      _vm.includes = $$v
                    },
                    expression: "includes",
                  },
                },
                [_vm._v(" + add ")]
              ),
            ],
            1
          ),
          _vm.includesToShow.length
            ? _c(
                "ul",
                _vm._l(_vm.includesToShow, function (includeShowned) {
                  return _c(
                    "li",
                    { key: includeShowned.id, staticClass: "item-list" },
                    [
                      _c("include-item-list", {
                        attrs: {
                          "allow-exclude": _vm.allowExclude,
                          include: includeShowned,
                          "read-only": _vm.readOnly,
                        },
                        on: {
                          edit: _vm.onNewInclude,
                          remove: function ($event) {
                            return _vm.removeItem(includeShowned.id, $event)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item-text",
                              fn: function ({ item }) {
                                return [
                                  _vm._t(
                                    "item-text",
                                    function () {
                                      return [
                                        _vm._v(" " + _vm._s(item.name) + " "),
                                      ]
                                    },
                                    { item: item, include: includeShowned }
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "div",
                [
                  _c("asterix-no-data", {
                    staticClass: "text-center",
                    attrs: {
                      title: "No includes yet",
                      subtitle:
                        "Use the add button above to create your first including",
                    },
                  }),
                ],
                1
              ),
          _vm.includeToCreate
            ? _c("include-modal", {
                attrs: {
                  "base-include": _vm.includeToCreate,
                  "allow-exclude": _vm.allowExclude,
                  title: _vm.includeToCreate.name,
                },
                on: {
                  cancel: _vm.onNewIncludeCancel,
                  update: _vm.onNewIncludeAdded,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }