var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c("basic-loader", { staticClass: "h-10 rounded block mb-8" }),
      _vm._l(_vm.boxes, function (i) {
        return _c("div", { key: i, staticClass: "mb-6" }, [
          _c(
            "div",
            { staticClass: "flex mb-4" },
            [
              _c("basic-loader", { staticClass: "h-4 rounded w-4" }),
              _c("basic-loader", { staticClass: "h-4 rounded ml-4 w-1/2" }),
            ],
            1
          ),
          _c("div", { staticClass: "ml-8" }, [
            _c(
              "div",
              { staticClass: "flex mb-4" },
              [
                _c("basic-loader", { staticClass: "h-4 rounded w-4" }),
                _c("basic-loader", { staticClass: "h-4 rounded ml-4 w-full" }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex mb-4" },
              [
                _c("basic-loader", { staticClass: "h-4 rounded w-4" }),
                _c("basic-loader", { staticClass: "h-4 rounded ml-4 w-1/2" }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex mb-4" },
              [
                _c("basic-loader", { staticClass: "h-4 rounded w-4" }),
                _c("basic-loader", { staticClass: "h-4 rounded ml-4 w-full" }),
              ],
              1
            ),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }