<template>
  <asterix-modal modal-class="asterix-modal-size h-full" :title="title" closable @cancel="$emit('cancel')">
    <template slot="content">
      <div class="flex w-full h-80vh overflow-hidden">
        <div class="pt-1 w-1/2 relative flex flex-col w-1/2">
          <target-header-modal
            :can-exclude="isIncludeMultiple && includeCanExclude"
            :can-include="isIncludeMultiple"
            :is-exclude-selected="isExcludeAllSelected"
            :is-include-selected="isIncludeAllSelected"
            :loading="isLoading"
            @exclude="excludeAllItems"
            @include="includeAllItems"
            @search="search"
          ></target-header-modal>

          <include-option-list-loader v-if="isLoading" class="px-2 overflow-hidden"></include-option-list-loader>
          <target-options-list
            v-else
            ref="fixedContainer"
            can-exclude
            can-include
            :excludes="excludes"
            :includes="includes"
            :is-exclude-visible="includeCanExclude"
            :multiple="isIncludeMultiple"
            :options="optionsFiltered"
            @exclude="onExcludePick"
            @include="onIncludePick"
          >
            <template #option="{ option }">
              {{ getOptionName(option) }}
            </template>
          </target-options-list>
        </div>

        <div v-if="!anyInclude && !anyExclude" class="w-1/2">
          <asterix-no-data
            title="There aren't items added"
            subtitle="You can add them using the controls on the option list"
            class="text-center p-2"
          />
        </div>
        <div v-else class="w-1/2">
          <target-options-selected-list
            v-if="anyInclude"
            class="options-selected"
            :class="{ 'h-50': anyExclude }"
            :items="includes"
            @remove="deleteFromList"
          >
            <template #title> Includes </template>
            <template #item="{ item }">
              {{ getOptionNameById(item) }}
            </template>
          </target-options-selected-list>

          <target-options-selected-list
            v-if="anyExclude"
            class="options-selected"
            :class="{ 'h-50': anyInclude }"
            :items="excludes"
            @remove="deleteFromList"
          >
            <template #title> Excludes </template>
            <template #item="{ item }">
              {{ getOptionNameById(item) }}
            </template>
          </target-options-selected-list>
        </div>
      </div>
    </template>

    <template #footer>
      <sun-button color="orange" variant="pill" class="custom-p-1 text-sm mr-2" @click="addInclude"> Apply </sun-button>
      <sun-button variant="pill" color="white" class="custom-p-1 text-sm hover:bg-gray-100" @click="cancelInclude">
        Cancel
      </sun-button>
    </template>
  </asterix-modal>
</template>
<script>
import AsterixModal from '@/components/organisms/shared/AsterixModal';
import TargetItem from '@/model/shared/TargetItem';
import IncludeItemNew from '@/model/shared/IncludeItemNew';
import IncludeOptionListLoader from '@/components/atoms/Loaders/IncludeOptionListLoader';
import TargetHeaderModal from './TargetHeaderModal';
import TargetOptionsList from './TargetOptionsList';
import TargetOptionsSelectedList from './TargetOptionsSelectedList';
import AsterixNoData from '@/components/organisms/shared/AsterixNoData';

export default {
  name: 'IncludeModalMultiple',
  components: {
    IncludeOptionListLoader,
    AsterixModal,
    TargetHeaderModal,
    TargetOptionsList,
    TargetOptionsSelectedList,
    AsterixNoData,
  },
  inject: ['services'],
  props: {
    /** @Type<TargetItem> */
    baseInclude: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      options: [],
      searchTerm: null,
      include: null,
    };
  },
  computed: {
    isIncludeAllSelected() {
      return (
        this.include.value.filter(item => item.type === IncludeItemNew.VALID_TYPES.INCLUDE).length ===
        this.options.length
      );
    },
    isExcludeAllSelected() {
      return (
        this.include.value.filter(item => item.type === IncludeItemNew.VALID_TYPES.EXCLUDE).length ===
        this.options.length
      );
    },
    includes() {
      return this.include.value
        .filter(item => item.type === IncludeItemNew.VALID_TYPES.INCLUDE)
        .map(item => item.value);
    },
    excludes() {
      return this.include.value
        .filter(item => item.type === IncludeItemNew.VALID_TYPES.EXCLUDE)
        .map(item => item.value);
    },
    anyInclude() {
      return !!this.includes.length;
    },
    anyExclude() {
      return !!this.excludes.length;
    },
    optionsFiltered() {
      let list = this.options;

      if (this.searchTerm) {
        const termLowerCase = this.searchTerm.toLowerCase();
        list = list.filter(item => {
          const itemText = item?.name || item?.id || item;
          return itemText.toLowerCase().indexOf(termLowerCase) > -1;
        });
      }

      return list;
    },
    isIncludeMultiple() {
      return this.include.multiple;
    },
    includeCanExclude() {
      return this.include.canExclude;
    },
  },
  async beforeMount() {
    this.include = TargetItem.deepClone(this.baseInclude);
    await this.fillOptions();
  },
  methods: {
    async fillOptions() {
      this.isLoading = true;
      this.options = this.options.concat((await this.services[this.baseInclude.id]?.(this.searchTerm))?.data);
      this.isLoading = false;
    },
    search(value) {
      this.searchTerm = value;
    },
    onIncludePick(option) {
      const includeIndex = this.findIncludeIndex(option, IncludeItemNew.VALID_TYPES.INCLUDE);
      if (includeIndex >= 0) {
        this.include.value.splice(includeIndex, 1);
        return;
      }

      const excludeIndex = this.findIncludeIndex(option, IncludeItemNew.VALID_TYPES.EXCLUDE);
      if (excludeIndex >= 0) {
        this.include.value.splice(excludeIndex, 1);
      }

      this.include.addItem(IncludeItemNew.VALID_TYPES.INCLUDE, option);
    },
    onExcludePick(option) {
      const excludeIndex = this.findIncludeIndex(option, IncludeItemNew.VALID_TYPES.EXCLUDE);
      if (excludeIndex >= 0) {
        this.include.value.splice(excludeIndex, 1);
        return;
      }

      const includeIndex = this.findIncludeIndex(option, IncludeItemNew.VALID_TYPES.INCLUDE);
      if (includeIndex >= 0) {
        this.include.value.splice(includeIndex, 1);
      }

      this.include.addItem(IncludeItemNew.VALID_TYPES.EXCLUDE, option);
    },
    findIncludeIndex(itemToFind, type) {
      return this.include.value.findIndex(item => item.value.id === itemToFind.id && item.type === type);
    },
    includeAllItems() {
      if (this.isLoading) return;

      if (this.includes.length !== this.options.length) {
        this.include.value = this.options.map(option => new IncludeItemNew(IncludeItemNew.VALID_TYPES.INCLUDE, option));
      } else {
        this.include.value = [];
      }
    },
    excludeAllItems() {
      if (this.isLoading) return;

      if (this.excludes.length !== this.options.length) {
        this.include.value = this.options.map(option => new IncludeItemNew(IncludeItemNew.VALID_TYPES.EXCLUDE, option));
      } else {
        this.include.value = [];
      }
    },
    deleteFromList(option) {
      const index = this.include.value.findIndex(item => item.value.id === option.id);
      this.include.value.splice(index, 1);
    },
    addInclude() {
      this.$emit('update', this.include);
    },
    cancelInclude() {
      this.$emit('cancel');
    },
    getOptionNameById(item) {
      const id = item?.id || item;
      const name = this.getOptionName(this.options.find(t => t?.id === id));
      if (!name) {
        const value = this.include.value.find(val => val.value.id === id);
        return value.name;
      }
      return name;
    },
    getOptionName(option) {
      return option?.name || option?.getDisplayName?.() || option?.id || option;
    },
  },
};
</script>
<style scoped>
.h-80vh {
  height: 80vh;
}

.h-50 {
  height: 50%;
}

.options-selected {
  @apply p-1 overflow-hidden;
}
</style>
