<template>
  <div v-if="!isLoading" class="w-full text-sm">
    <sun-form v-if="target" :key="keyForm" @submit="onSubmit">
      <card-form>
        <template #title>Include list</template>
        <template #form>
          <include-creator-multiple :includes="includes">
            <template #item-text="{ item }">
              {{ item.name }}
            </template>
          </include-creator-multiple>
        </template>
      </card-form>
      <div class="mb-4 mt-12 form-row flex flex-col lg:flex-row w-full">
        <save-button class="mx-1" :disabled="readOnly" :loading="isSubmiting"></save-button>
      </div>
    </sun-form>
  </div>
  <div v-else>
    <card-form-loading></card-form-loading>
    <card-form-loading class="mt-10"></card-form-loading>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import metaInfo from '@/mixins/common/metaInfo';
import CardForm from '@/components/atoms/CardForm';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import SaveButton from '@/components/atoms/SaveButton';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import Campaign from '@/entities/socialAudience/Campaign';
import TargetVideo from '@/entities/socialAudience/TargetVideo';
import { createTargeting, updateTargeting, getTargetingVideo } from '@/services/modules/socialAudience/targeting';
import { Toast } from '@/model/shared/Toast';
import { COUNTRY_NS, COUNTRIES_KEY, GET_COUNTRIES_REQUEST } from '@/store/modules/country/keys';
import { BROWSER_NS, BROWSERS_VIDEO_PLAZA_KEY, GET_BROWSERS_VIDEO_PLAZA_REQUEST } from '@/store/modules/browser/keys';
import { DEVICE_NS, DEVICES_VIDEO_PLAZA_KEY, GET_DEVICES_VIDEO_PLAZA_REQUEST } from '@/store/modules/device/keys';
import { OS_NS, OS_VIDEO_PLAZA_KEY, GET_OS_VIDEO_PLAZA_REQUEST } from '@/store/modules/operatingSystem/keys';
import {
  CONTENT_TARGETING_NS,
  CONTENT_TARGETING_KEY,
  GET_CONTENT_TARGETING_REQUEST,
} from '@/store/modules/contentTargeting/keys';
import IncludeCreatorMultiple from '@/components/organisms/shared/includes/IncludeCreatorMultiple';
import { list } from '@/router/private/modules/socialAudience/demand/campaign/list';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';

export default {
  name: 'TargetingVideo',
  components: {
    CardForm,
    CardFormLoading,
    SaveButton,
    IncludeCreatorMultiple,
  },
  mixins: [metaInfo],
  provide() {
    return {
      services: {
        [TargetVideo.TARGET_IDS.GEO]: this.getCountries,
        [TargetVideo.TARGET_IDS.BROWSER]: this.getBrowsers,
        [TargetVideo.TARGET_IDS.DEVICE]: this.getDevices,
        [TargetVideo.TARGET_IDS.OS]: this.getOS,
        [TargetVideo.TARGET_IDS.CONTENT_TARGETING]: this.getContentTargeting,
      },
    };
  },
  props: {
    campaign: {
      type: Campaign,
      default: () => null,
    },
    insertionId: {
      type: String,
      default: () => null,
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    keyForm: 0,
    target: null,
    isLoading: false,
    isSubmiting: false,
  }),
  computed: {
    ...mapState(COUNTRY_NS, {
      countryList: COUNTRIES_KEY,
    }),
    ...mapState(BROWSER_NS, {
      browserList: BROWSERS_VIDEO_PLAZA_KEY,
    }),
    ...mapState(DEVICE_NS, {
      deviceList: DEVICES_VIDEO_PLAZA_KEY,
    }),
    ...mapState(OS_NS, {
      OSList: OS_VIDEO_PLAZA_KEY,
    }),
    ...mapState(CONTENT_TARGETING_NS, {
      contentTargetingList: CONTENT_TARGETING_KEY,
    }),
    includes() {
      return this.target.getItems();
    },
  },
  async created() {
    if (!this.campaign) return;

    this.isLoading = true;
    this.target = (await this.getTargeting()) || new TargetVideo();
    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      getCountryList: GET_COUNTRIES_REQUEST,
      getBrowserList: GET_BROWSERS_VIDEO_PLAZA_REQUEST,
      getDeviceList: GET_DEVICES_VIDEO_PLAZA_REQUEST,
      getOSList: GET_OS_VIDEO_PLAZA_REQUEST,
      getContentTargetingList: GET_CONTENT_TARGETING_REQUEST,
      createToast: CREATE_TOAST,
    }),
    metaTitleReplacement() {
      return { campaign: this.campaign.name };
    },
    async getTargeting() {
      try {
        const { data } = await getTargetingVideo(this.insertionId);
        return data[0];
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel && error.code !== HTTPStatusCode.Locked) {
          this.createToast(Toast.error('Targeting not loaded', error.message));
        }
      }
    },
    async onSubmit() {
      this.isSubmiting = true;
      if (this.target.id) {
        await this.updateTargeting();
      } else {
        await this.createTargeting();
      }
      this.isSubmiting = false;
      this.goToCampaignList();
    },
    async createTargeting() {
      try {
        await createTargeting(this.target, this.insertionId);
        this.createToast(Toast.success('Targeting saved', `Targeting was saved successfully.`));
      } catch (error) {
        this.createToast(Toast.error('Targeting not saved', error.message));
      }
    },
    async updateTargeting() {
      try {
        await updateTargeting(this.target);
        this.createToast(Toast.success('Targeting saved', `Targeting was saved successfully.`));
      } catch (error) {
        this.createToast(Toast.error('Targeting not saved', error.message));
      }
    },
    async getCountries() {
      await this.getCountryList();

      return {
        data: this.countryList,
      };
    },
    async getBrowsers() {
      if (this.browserList.length === 0) {
        await this.getBrowserList();
      }
      return {
        data: this.browserList,
      };
    },
    async getDevices() {
      if (this.deviceList.length === 0) {
        await this.getDeviceList();
      }
      return {
        data: this.deviceList,
      };
    },
    async getOS() {
      if (this.OSList.length === 0) {
        await this.getOSList();
      }
      return {
        data: this.OSList,
      };
    },
    async getContentTargeting() {
      if (this.contentTargetingList.length === 0) {
        await this.getContentTargetingList();
      }
      return {
        data: this.contentTargetingList.map(item => ({ ...item, visible: true })),
      };
    },
    goToCampaignList() {
      this.$router.push(list);
    },
  },
};
</script>

<style scoped>
::v-deep .block-list .multiselect__option {
  @apply hidden;
}
::v-deep .block-list .multiselect__select {
  @apply hidden;
}
::v-deep .block-list .multiselect__tags input {
  @apply mb-0;
}
</style>
