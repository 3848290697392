import { api } from '.';
import { INSERTION_RESOURCE, TARGETING_RESOURCE, TARGETING_VIDEO_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import socialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';

export const getResources = insertionId => ({
  [INSERTION_RESOURCE]: insertionId,
  [TARGETING_RESOURCE]: undefined,
});

/**
 *
 * @param {String} insertionId
 * @returns {Promise.<{data:insertion,meta:EndpointMeta}>}
 */
export async function getTargetingVideo(insertionId) {
  const partialUrl = api.createUrl(getResources(insertionId));

  const { data } = await api.get(partialUrl);
  data.data.forEach(item => {
    item.type = TARGETING_VIDEO_RESOURCE;
  });

  return new ResponseBuilder(socialAudienceEntityParser.parse(data), data.meta).build();
}
