var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-dropdown", {
    attrs: { options: _vm.targetOptions, disabled: _vm.disabled },
    on: { change: _vm.onOptionsChange },
    scopedSlots: _vm._u(
      [
        {
          key: "button",
          fn: function () {
            return [
              _c(
                "sun-button",
                {
                  staticClass:
                    "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800 relative",
                  attrs: {
                    variant: "pill",
                    color: "gray",
                    disabled: _vm.disabled,
                  },
                },
                [
                  _vm._t("default", function () {
                    return [_vm._v("Select")]
                  }),
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "option",
          fn: function (slotProps) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "target-list-item flex justify-between px-3 text-xs font-bold text-gray-500 hover:text-white hover:bg-gray-700 rounded-full",
                },
                [
                  _c("p", [
                    _vm._v(" " + _vm._s(slotProps.option.option.name) + " "),
                  ]),
                  _vm.addedOptions.findIndex(
                    (t) => t.id === slotProps.option.option.id
                  ) > -1
                    ? _c(
                        "p",
                        { staticClass: "ml-6 text-xxs text-gray-600" },
                        [
                          _vm._t("added", function () {
                            return [_vm._v(" Added ")]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }