import { api } from '.';
import { TARGETING_RESOURCE } from '@/services/keys';

export const getResources = targetingId => ({
  [TARGETING_RESOURCE]: targetingId,
});

/**
 *
 * @param {Target} targeting
 * @return {Promise<string>}
 */
export async function updateTargeting(targeting) {
  const partialUrl = api.createUrl(getResources(targeting.id));
  const {
    data: {
      targeting: { id },
    },
  } = await api.update(partialUrl, targeting.payload());
  return id;
}
